// 定义地方馆需要的接口函数
import request from '@/utils/request'


/**
 * 地方馆 - 列表
 * @returns Promise
 */
export const getVenueList = (data) => {
  return request('/honghu-activity-service/venue/getVenueList', 'get', data)
}

/**
 * 地方馆 - 省详情
 * @returns Promise
 */
export const getVenueById = (data) => {
  return request('/honghu-activity-service/venue/getVenueById', 'get', data)
}

/**
 * 地方馆 - 省馆首页 - 推荐商品/场馆推荐
 * @returns Promise
 */
export const getRecommendMapByVenueId = (data) => {
  return request('/honghu-activity-service/venue/getRecommendMapByVenueId', 'get', data)
}

/**
 * 地方馆 - 风俗文化/生态旅游
 * @returns Promise
 */
export const getContentByVenueId = (data) => {
  return request('/honghu-activity-service/venue/getContentByVenueId', 'get', data)
}

/**
 * 地方馆 - 特色商品
 * @returns Promise
 */
export const getGoodsListByVenueId = (data) => {
  return request('/honghu-activity-service/venue/getGoodsListByVenueId', 'get', data)
}
