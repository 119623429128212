import {
  Ref,
  isRef,
  ref,
  watchEffect
} from 'vue';
import {
  isFunction
} from '.';



export function getTargetElement (
  target,
  defaultElement
) {
  const targetElement = ref();
  if (isFunction(target)) {
    targetElement.value = target();
  } else if (isRef(target)) {
    watchEffect(
      () => {
        targetElement.value = target.value;
      }, {
      flush: 'sync'
    }
    );
  } else {
    targetElement.value = target || defaultElement;
  }
  return targetElement;
}