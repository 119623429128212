import { Ref, ref, customRef, unref } from 'vue'

const useNumRangeRef = (num, { min, max }) => {
  if (min === void 0 && max === void 0) {
    // console.warn('为啥不指定最小值和最大值？')
    return ref(num)
  }

  return customRef((track, trigger) => {
    return {
      get () {
        track()
        return num
      },
      set (newNum) {
        num = Math.min(unref(max) ?? Infinity, Math.max(unref(min) ?? -Infinity, newNum))
        trigger()
      }
    }
  })
}

export default useNumRangeRef