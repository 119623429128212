import throttle from 'lodash.throttle'

const useThrottleFn = (
    fn,
    wait = 0,
    options
) => {
    const run = throttle(fn, wait, options)

    return [run , run.cancel]
}

export default useThrottleFn