// 定义首页需要的接口函数
import request from '@/utils/request'
// 接口参考H5版本.  


/**
 * 首页-广告区域
 * @returns Promise
 */
export const getBannerList = () => {
  return request('/honghu-app-service/banner/bannerList', 'get', { type: 1 })
}

/**
 * 首页 - 地方馆首页地图推荐列表
 * @returns Promise
 */
export const getVenueOfFirstPage = (data) => {
  return request('/honghu-activity-service/venue/getVenueOfFirstPage', 'get', data)
}
/**
 * 首页 - 地方馆首页地图推荐列表
 * @returns Promise
 */
export const getChinaData = (data) => {
  return request('/honghu-activity-service/file/getChinaData', 'get', data)
}

/**
 * 首页 - 地方馆首页地图推荐列表
 * @returns Promise
 */
export const getVenueHeatOfFirstPage = (data) => {
  return request('/honghu-activity-service/venue/getVenueHeatOfFirstPage', 'get', data)
}

/**
 * 首页 - 主题区
 * @returns Promise
 */
export const getThemeList = (data) => {
  return request('/honghu-app-service/theme/list', 'get', data)
}

/**
 * 首页 - 活动商品海报
 * @returns Promise
 */
export const getActivityBannerList = (data) => {
  return request('/honghu-app-service/banner/activityBannerList', 'get', data)
}

/**
 * 首页 - 文化馆各地习俗列表
 * @returns Promise
 */
export const getCultureListInfo = (data) => {
  return request('/honghu-app-service/recommendPart/culture/listInfo', 'get', data)
}

/**
 * 首页 - 文化馆精选短视频
 * @returns Promise
 */
export const getCultureVideoList = (data) => {
  return request('/honghu-app-service/recommendPart/videoInfo', 'get', data)
}

/**
 * 首页 - 文化馆精选文章
 * @returns Promise
 */
export const getCultureEssayList = (data) => {
  return request('/honghu-app-service/recommendPart/essayInfo', 'get', data)
}

/**
 * 首页 - 活动列表
 * @returns Promise
 */
export const getActivesList = (data) => {
  return request('/honghu-app-service/actives/list', 'get', data)
}

/**
 * 首页 - 商品分类
 * @returns Promise
 */
export const getRecommendPartList = (data) => {
  return request('/honghu-app-service/recommendPart/list', 'get', data)
}

/**
 * 首页 - 根据商品分类查询对应商品
 * @returns Promise
 */
export const getGoodsList = (data) => {
  return request('/honghu-app-service/recommendPart/goods/list', 'get', data)
}

/**
 * 首页 - 分类类别
 * @returns Promise
 */
export const getShowClass = (data) => {
  return request('/honghu-goodsclass-service/ypt/get/getShowClass', 'get', data)
}

/**
 * 首页 - 搜索关键词列表
 * @returns Promise
 */
export const searchHotWordList = (data) => {
  return request('/honghu-search-service/search/searchHotWordList', 'get', data)
}


/**
 * 家乡贡献值 - 贡献值商品列表
 * @returns Promise
 */
export const getConvertGoodsInfo = (data) => {
  return request('/honghu-activity-service/home/chart/getConvertGoodsInfo', 'get', data)
}


/**
 * 地方馆 - 全部场馆
 * @returns Promise
 */
export const getAllProVenueList = (data) => {
  return request('/honghu-activity-service/venue/getAllProVenueList', 'get', data)
}