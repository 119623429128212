// 1.导入用于创建vue实例的函数 createApp
import { createApp } from 'vue'
// 2.导入根组件
import App from './App.vue'
// 3.导入路由实例
import router from './router'
// 4.导入仓库实例
// import store from './store'

// 导入自己封装的组件库
import qdzUI from '@/components/library'


import * as gobalMethods from './utils/methods'
// 重置样式
import 'normalize.css'
import '@/assets/styles/common.less'
import '@/assets/styles/iconfont-p.css' // 拓展字体图标文件
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
// 5.创建vue实例
const app = createApp(App)
// 6.注册使用
// app.use(store)
app.use(router)
// 安装自己封装的组件库
app.use(qdzUI)
// 设置全局属性
for (const key in gobalMethods) {
  app.config.globalProperties[`$${key}`] = gobalMethods[key]
}

// 7.挂载到app实例
app.mount('#app')

