<template>
  <!-- 2.1 普通头部布局-->
  <AppHeader></AppHeader>

  <!-- 2.2 固定头部布局-->
  <AppHeaderSticky></AppHeaderSticky>
  <main>
    <!-- 二级路由 -->

    <RouterView v-slot="{ Component }">
      <keep-alive :include="['home','localPavilion','culturalCenter','contributionValue']">
        <component :is="Component" />
      </keep-alive>
    </RouterView>
  </main>
  <AppFooter></AppFooter>
  <AppBackTop />
</template>

<script>
import AppHeader from '@/components/app-header.vue'
import AppFooter from '@/components/app-footer.vue'
import AppHeaderSticky from '@/components/app-header-sticky.vue'
import AppBackTop from '@/components/app-backTop.vue'

export default {
  components: { AppHeader, AppFooter, AppHeaderSticky, AppBackTop },
  name: 'qdz-layout',
}
</script>

<style scoped lang="less">
::v-deep(.el-backtop) {
  overflow: hidden;
}
</style>
