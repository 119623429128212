import basePicUrl from '../../basePicUrl'
/** 
 * @url 传入一个
 * @returns fullUrl
*/
function addUrlPrefix (url) {
  if (url !== '' && url !== null && url) {
    // 判断图片是否以 http/https 开头
    let reg = new RegExp("^(http|https)://", "i");

    if (reg.test(url)) {
      // 有前缀直接返回
      return url
    };

    // 没有前缀加域名前缀
    return basePicUrl + url
  };
}
// 自定义日期格式
/** 
 * @d 传入一个时间戳
 * @m 传入一个时间格式
 * @returns 转好后的时间格式
*/
function timestampFormat (d, m) {
  // console.log(d)
  var date = new Date(d || 0)
  var yy = date.getFullYear(), mm = date.getMonth() + 1, dd = date.getDate(), hh = date.getHours(), nn = date.getMinutes(), ss = date.getSeconds();
  switch (m) {
    case "yy-mm-dd":
      return yy + "-" + addZero(mm) + "-" + addZero(dd);
    case "yy-mm-dd hh:nn:ss":
      return yy + "-" + addZero(mm) + "-" + addZero(dd) + " " + addZero(hh) + ":" + addZero(nn) + ":" + addZero(ss);
    case "yy/mm/dd hh:nn:ss":
      return yy + "/" + addZero(mm) + "/" + addZero(dd) + " " + addZero(hh) + ":" + addZero(nn) + ":" + addZero(ss);
      break;
    case "yy年mm月dd日":
      return yy + "年" + addZero(mm) + "月" + addZero(dd) + "日";
      break;
    case "nn:ss":
      return addZero(nn) + ":" + addZero(ss);
      break;
    case "":
      return yy + "-" + addZero(mm) + "-" + addZero(dd);
      break;
    default:
  }
}
// 日期小于10补零
function addZero (n) {
  return n < 10 ? "0" + n : n
}

// 数字保留两位小数
function keepTwoDecimalFull (value) {
  if (!value && value !== 0) return '';

  if (value === 0) return '0.00';

  var value = Math.round(parseFloat(value) * 100) / 100;
  var xsd = value.toString().split(".");

  if (xsd.length == 1) {
    value = value.toString() + ".00";
    return value
  };

  if (xsd.length > 1) {
    if (xsd[1].length < 2) {
      value = value.toString() + "0"
    };
    return value
  }
}

// 阅读量数字处理
function readNumFormat (number) {
  let num = Number(number)
  if (isNaN(num)) return 0
  if (num >= 10000) { num = Math.round(num / 1000) / 10 + 'w' }
  else if (num >= 1000) { num = Math.round(num / 100) / 10 + 'k' }
  return num
}

export {
  addUrlPrefix,
  timestampFormat,
  keepTwoDecimalFull,
  readNumFormat
}