// createRouter              创建路由实例的函数
// createWebHashHistory      创建哈希Hash路由函数(推荐)
// createWebHistory          创建History路由函数
import { createRouter, createWebHashHistory, beforeRouteEnter } from 'vue-router'
import Layout from '@/views/Layout.vue'
import Home from '@/views/home/index.vue'

// 路由：路径和组件映射关系
const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', component: Home },
      { path: '/themeActive', component: () => import('@/views/home/theme-active.vue') },
      { path: '/about/:id', component: () => import('@/views/about/index.vue') },
      { path: '/search', component: () => import('@/views/search/index.vue') },
      { path: '/goods', component: () => import('@/views/goods/index.vue') },
      { path: '/localPavilion', component: () => import('@/views/localPavilion/index.vue') },
      { path: '/localPavilion/cityPavilion', component: () => import('@/views/localPavilion/cityPavilion/index.vue') },
      { path: '/culturalCenter', component: () => import('@/views/culturalCenter/index.vue') },
      { path: '/culturalCenter/detail', component: () => import('@/views/culturalCenter/detail.vue') },
      { path: '/contributionValue', component: () => import('@/views/contributionValue/index.vue') },
      { path: '/ambassador', component: () => import('@/views/ambassador/index.vue') },
      {
        path: '/:catchAll(.*)',
        name: '/404',
        component: () => import('@/views/404/index.vue')
      },
    ]
  }
]
function scrollBehavior (to, from, savedPosition) {
  // `to` 和 `from` 都是路由地址
  // `savedPosition` 可以为空，如果没有的话。
  // console.log(to, from, savedPosition)
  // 在切换页面的时候可以根据页面设置锚点/滚动位置
  if (savedPosition) {
    return savedPosition
  } else {
    return { top: 0 }
  }
}

// 创建路由实例
const router = createRouter({
  // 哈希路由
  history: createWebHashHistory(),
  // 路由映射配置
  routes,
  scrollBehavior
})


// 导出路由实例
export default router
