<template>
  <div class="container"
       ref="cont">
    <div class="wrap"
         ref="wrap">
      <div class="item"
           v-for="(item, index) in currentList"
           :key="index">item:{{ item.data }}</div>
    </div>
  </div>
</template>

<script >
import { ref } from 'vue'
import { useVirtualList } from '@/hooks/useVIrtualList.js'

export default {
  props: {
    list: {
      type: Array,
      default: () => Array.from(Array(100000).keys())
    }
  },
  setup (props) {
    // console.log(props)
    const wrap = ref()
    const cont = ref()
    const [currentList] = useVirtualList(
      { container: cont, wrap, list: props.list },
      {
        overscan: 5,
        itemHeight: 80
      }
    )
    return {
      wrap,
      cont,
      currentList
    }
  }
}

</script>

<style scoped>
.container {
  width: 1000px;
  height: 500px;
  overflow: auto;
}
.item {
  width: 100%;
  height: 80px;
  border: 1px solid #ddd;
}
</style>
