<template>
  <div class='home-category'>
    <div class="category-title">商品分类</div>
    <ul class="menu scroll">
      <li v-for="item in menuList"
          :key="item.id"
          :class="{active : categoryId === item.id}"
          @click='handleCheckClass(item.id)'>
        <div class="ellipsis">{{item.className}}</div>
      </li>

    </ul>
    <!-- 弹层 -->
    <div class="layer">
      <div v-if="currCategory && currCategory.length"
           class="category-content">
        <RouterLink :to="`/search?id=${item.id}`"
                    class="category-item"
                    v-for="item in currCategory"
                    :key="item.id">
          <img class="category-pic"
               v-lazy="item.logoUrl" />
          <p class="category-name ellipsis">{{ item.className }}</p>
        </RouterLink>
      </div>

    </div>
  </div>

</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue'
import { getShowClass } from '@/api/home'
export default {
  name: 'HomeCategory',
  setup () {
    const state = reactive({
      menuList: [],
      currCategory: [],
      categoryId: ''
    })

    getShowClass().then(({ data }) => {
      // console.log(data.showClass)
      state.menuList = data.showClass
      state.categoryId = state.menuList[0].id
      handleCheckClass(state.categoryId)
    })
    const handleCheckClass = async (id) => {
      state.categoryId = id
      let { data } = await getShowClass({ id })
      state.currCategory = data.showClass
      // console.log(state.currCategory)

    }
    // 计算数据内部处理数据
    // const menuList = computed(() => {
    //   // 处理从 Vuex 取出来的数据
    //   const arr = store.state.category.list.map(item => ({
    //     ...item,
    //     children: item.children && item.children.slice(0, 2)
    //   }))
    //   arr.push(brand)
    //   return arr
    // })
    return {
      ...toRefs(state),
      handleCheckClass
    }
  }

}
</script>

<style scoped lang="less">
.home-category {
  width: 281px;
  height: 525px;
  background: #fff;
  position: relative;
  z-index: 99;
  padding: 2px;
  .category-title {
    width: 277px;
    height: 47px;
    line-height: 47px;
    background: #8c3737;
    color: #fff;
    font-size: 16px;
    padding-left: 14px;
  }
  .menu {
    width: 74px;
    overflow: auto;
    height: 478px;

    li {
      padding: 0 12px;
      height: 50px;
      line-height: 50px;
      font-size: 12px;
      cursor: pointer;
      &.active {
        background: #8c3737;
        color: #fff;
        a {
          font-weight: 500;
        }
      }
      a {
        font-weight: 400;
        color: #111111;
        font-size: 14px;
      }
    }
  }
  .layer {
    width: 200px;
    height: 478px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: -2px;
    left: 76px;
    // display: none;
    padding: 15px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }

    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 0 3px RGB(243, 243, 244);
    }
    h4 {
      font-size: 20px;
      font-weight: normal;
      line-height: 80px;
      small {
        font-size: 16px;
        color: #666;
      }
    }
    .category-content {
      display: flex;
      flex-wrap: wrap;
    }
    .category-item {
      width: 78px;
      margin-right: 10px;
      margin-bottom: 12px;
      &:nth-child(even) {
        margin-right: 0;
      }
      .category-pic {
        width: 78px;
        height: 78px;
        margin-bottom: 10px;
      }
      .category-name {
        width: 78px;
        font-size: 12px;
        font-weight: 400;
        color: #111111;
        text-align: center;
      }
    }
  }
}
</style>
