<template>
  <div class="empty-container">
    <template v-if="state === '404'">
      <img class="img-pic"
           src="../../assets/images/state/404.png" />
      <span class="tips-text">抱歉～页面不见了</span>
    </template>
    <template v-if="state === 'network'">
      <img class="img-pic"
           src="../../assets/images/state/network.png" />
      <span class="tips-text">抱歉～网络不给力</span>
    </template>
    <template v-if="state === 'notData'">
      <img class="img-pic"
           src="../../assets/images/state/notData.png" />
      <span class="tips-text">抱歉～暂无数据</span>
    </template>
    <template v-if="state === 'notResult'">
      <img class="img-pic"
           src="../../assets/images/state/notResult.png" />
      <span class="tips-text">抱歉～暂无搜索结果</span>
    </template>
  </div>
</template>
<script >
export default {
  name: 'qdzEmpty',
  props: {
    state: {
      type: String,
      default: () => "404"
    },
  }
}
</script>
<style lang="less">
.empty-container {
  width: 600px;
  height: 600px;
  margin: 0 auto;
  text-align: center;
  .img-pic {
    width: 550px;
    height: 550px;
    margin: 0 auto;
    display: block;
  }
  .tips-text {
    font-size: 19px;
    font-weight: 400;
    color: #5f6674;
  }
}
</style>