<template>
  <div :class="classes"
       :style="styles"
       @click="back">
    <slot>
      <div :class="innerClasses"
           class="icon-content">
        <div class="up"></div>
      </div>
    </slot>
  </div>
</template>
<script >
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
} from "vue";
import { scrollTop } from "../utils/utils";
// import { on, off } from '../../utils/dom'

const prefixCls = "qdz-back-top";

export default defineComponent({
  props: {
    height: {
      type: Number,
      default: 400,
    },
    bottom: {
      type: Number,
      default: 100,
    },
    right: {
      type: Number,
      default: 40,
    },
    duration: {
      type: Number,
      default: 1000,
    },
  },
  setup (props, ctx) {
    const { height, bottom, right, duration } = toRefs(props);
    const backTop = ref(false);

    const classes = computed(() => {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-show`]: backTop.value,
        },
      ];
    });
    const styles = computed(() => {
      return {
        bottom: `${bottom.value}px`,
        right: `${right.value}px`,
      };
    });
    const innerClasses = computed(() => {
      return `${prefixCls}-inner`;
    });
    const handleScroll = () => {
      backTop.value = window.pageYOffset >= height.value;
    };
    const back = () => {
      const sTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop(window, sTop, 0, duration.value);
      ctx.emit("on-click");
    };

    onMounted(() => {
      // on(window, 'scroll', handleScroll)
      // on(window, 'resize', handleScroll)
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    });

    return {
      classes,
      styles,
      innerClasses,
      handleScroll,
      back,
    };
  },
});
</script>
<style scoped >
.qdz-affix,
.qdz-back-top {
  position: fixed;
  z-index: 10;
}
.qdz-back-top {
  cursor: pointer;
  display: none;
}
.qdz-back-top.qdz-back-top-show {
  display: block;
}
.qdz-back-top-inner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  color: #8b3a39;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.qdz-back-top-inner:hover {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.qdz-back-top i {
  color: #fff;
  font-size: 24px;
  padding: 8px 12px;
}

.icon-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #883f40;
}
</style>