<template>
  <div class="home-skeleton container">
    <div class="item"
         v-for="i in 10"
         :key="i"
         :style="{ backgroundColor: bg }">
      <qdzSkeleton bg="#e4e4e4"
                   width="240px"
                   height="217px"
                   animated />
      <qdzSkeleton bg="#e4e4e4"
                   width="160px"
                   height="24px"
                   animated />
      <qdzSkeleton bg="#e4e4e4"
                   width="120px"
                   height="24px"
                   animated />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeSkeleton',
  props: {
    bg: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style scoped lang="less">
.home-skeleton {
  width: 1240px;
  height: 738px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  .item {
    width: 240px;
    margin-right: 10px;
    margin-bottom: 28px;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .qdz-skeleton ~ .qdz-skeleton {
      display: block;
      margin: 10px auto 0;
    }
  }
}
</style>
