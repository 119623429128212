// 组件库的入口
//  主要职责：导入组件，全局注册组件
const importFn = require.context('./', false, /\.vue$/)

// 注册全局组件 将来这样的组件建议在vue 插件中定义
// 导入加载失败是展示的图片

export default {
  install (app) {
    //  把这个组件设置为全局指令
    // app.component(XtxSkeleton.name, XtxSkeleton)
    // app.component(XtxBread.name, XtxBread)
    // app.component(XtxBreadItem.name, XtxBreadItem)
    // app.component(XtxMore.name, XtxMore)

    // 格局keys 批量注册
    importFn.keys().forEach((item) => {
      // 导入组件
      const component = importFn(item).default
      // 注册
      app.component(component.name, component)
    })

    defineDirective(app)
  }
}
import defaultIMG from '@/assets/images/200.png'
import { addUrlPrefix } from '@/utils/methods'
// 定义指令
const defineDirective = (app) => {
  // 图片懒加载 v-lazy
  // 原理：先存储图片地址不能在src上，当图片进入可视区域，将你存储图片地址设置给图片元素即可
  app.directive('lazy', {

    mounted (el, binding) {
      // 创建一个观察对象，来观察当前使用 指令的元素
      const observe = new IntersectionObserver(([{
        isIntersecting
      }]) => {
        if (isIntersecting) {
          // 停止观察
          observe.unobserve(el)
          // 把指令的值设置给el 的src属性 binding.value 就是指令值
          // console.log(binding.value)
          el.src = addUrlPrefix(binding.value)
          // 处理图片加载失败 onerror 是图片加载失败的事件
          // load 图片加载成功
          el.onerror = () => {
            // 加载失败设置默认图片
            el.src = defaultIMG
          }
        }
      }, {
        threshold: 0.01
      })
      // 开启观察
      observe.observe(el)
    },
  })
}

