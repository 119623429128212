<template>
  <footer class="app-footer">
    <!-- 联系我们 -->
    <div class="contact">
      <div class="container">
        <div class="line-bar">
          <dl>
            <dt> <a href="">好实再首页 </a></dt>
          </dl>
          <dl>
            <dt> <a href="#/about/1" target="_blank">关于好实再 </a></dt>
          </dl>
          <!-- <dl>
            <dt> <a href="#/about/5" target="_blank">购物指南 </a></dt>
          </dl> -->
          <dl>
            <dt> <a href="#/about/2" target="_blank">联系我们 </a></dt>
          </dl>
          <dl>
            <dt> <a href="#/about/3" target="_blank">保护隐私 </a></dt>
          </dl>
          <dl>
            <dt> <a href="#/about/4" target="_blank">免责条款 </a></dt>
          </dl>
        </div>
        <div class="func-bar">
          <div class="qrcode-item">
            <div class="qrcode-box">
              <img class="qrcode" src="../assets/images/footer/qr-1.png" alt="购物省，开店赚，手机端更多优惠" />

            </div>
            <div class="qr-right">
              <div class="qrcode-title">
                购物省，开店赚，手机端更多优惠
              </div>
              <div class="qrcode-btn">下载APP客户端</div>
            </div>
          </div>
          <div class="line-80"></div>
          <div class="qrcode-item">
            <div class="qrcode-box">
              <img class="qrcode" src="../assets/images/footer/qr-2.png" alt="微信号：kjgxpt" />
            </div>
            <div class="qr-right">
              <div class="qrcode-title">
                微信号：kjgxpt
              </div>
              <div class="qrcode-btn">好实再微信服务号</div>
            </div>
          </div>
          <div class="line-80"></div>
          <div class="qrcode-item">
            <div class="qrcode-box">
              <img class="qrcode" src="../assets/images/footer/qr-2.png" alt="微信号：haoshizai88" />

            </div>
            <div class="qr-right">
              <div class="qrcode-title">
                微信号：haoshizai88
              </div>
              <div class="qrcode-btn">好实再微信服务号</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 其它 -->
    <div class="extra">
      <div class="container">
        <!-- 版权信息 -->
        <div class="copyright">
          <p class="line-p">
            <a href="https://qdz.hsz88.com/pdf/pc_cert/1.pdf" target="_blank">增值电信业务经营许可证（EDI）：粤B2-20150376</a>
            <a href="https://qdz.hsz88.com/pdf/pc_cert/2.png" target="_blank">开户许可证</a>
            <a href="https://qdz.hsz88.com/pdf/pc_cert/3.png" target="_blank">食品经营许可证</a>
            <a href="https://qdz.hsz88.com/pdf/pc_cert/4.png" target="_blank">营业执照</a>
          </p>
          <p class="line-p1">
            <a href="https://beian.miit.gov.cn/" target="_blank">版权所有 好实再商城 保留所有版权 粤ICP备14099383 号</a>
            <a href="javascript:;">Copyright@2009-2021 www.hsz88.com.All Rights Reserved</a>
          </p>
          <div class="line-pic-bar">
            <a class="a-item" href="http://ga.sz.gov.cn/JMHD/" target="_blank"></a>
            <a class="a-item" href="https://credit.cecdc.com/CX20150923011818180180.html" target="_blank"></a>
            <a class="a-item"
              href="https://ss.knet.cn/verifyseal.dll?sn=e15010744030057226v3hr000000&comefrom=trust&trustKey=dn&trustValue=www.hsz88.com"
              target="_blank"></a>
            <a class="a-item" href="http://www.bctc-lab.com.cn/" target="_blank"></a>
            <a class="a-item" href="https://szcert.ebs.org.cn/427d1d44-3824-497a-9afc-a4b8a9ddf246" target="_blank"></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="less">
.app-footer {
  overflow: hidden;
  background-color: #f5f5f5;

  .contact {
    // background: #fff;
    background: #111;

    .line-bar {
      display: flex;
      height: 132px;
      align-items: center;
      justify-content: center;

      a {
        color: #fff;

        &:hover {
          color: #fadc54;
          text-decoration: underline;
        }
      }
    }

    .func-bar {
      display: flex;
      height: 271px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      align-items: center;

      .qrcode-item {
        display: flex;
        align-items: center;

        .qrcode-box {
          width: 155px;
          height: 155px;
          background-color: #fff;
          padding: 10px;
          box-sizing: border-box;
        }

        .qr-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 19px;

          .qrcode-title {
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 16px;
          }
        }

        .qrcode-btn {
          width: fit-content;
          padding: 0 10px;
          height: 35px;
          line-height: 35px;
          background: #fadc54;
          font-size: 12px;
          font-weight: 600;
          color: #111111;
        }
      }

      .line-80 {
        width: 1px;
        height: 136px;
        margin: 0 70px;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    dl {
      // height: 190px;
      text-align: center;
      padding: 0 72px;
      border-right: 1px solid #f2f2f2;
      color: #999;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }

    dt {
      line-height: 1;
      font-size: 18px;
    }

    dd {
      margin: 36px 12px 0 0;
      float: left;
      width: 92px;
      height: 92px;
      padding-top: 10px;
      border: 1px solid #ededed;

      .iconfont {
        font-size: 36px;
        display: block;
        color: #666;
      }

      &:hover {
        .iconfont {
          color: @qdzColor;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .qrcode {
      width: 136px;
      height: 136px;
      background-color: #000;
      // padding: 7px;
      // border: 1px solid #ededed;
    }

    .download {
      padding-top: 5px;
      font-size: 14px;
      width: auto;
      height: auto;
      border: none;

      span {
        display: block;
      }

      a {
        display: block;
        line-height: 1;
        padding: 10px 25px;
        margin-top: 5px;
        color: #fff;
        border-radius: 2px;
        background-color: @qdzColor;
      }
    }

    .hotline {
      padding-top: 20px;
      font-size: 22px;
      color: #666;
      width: auto;
      height: auto;
      border: none;

      small {
        display: block;
        font-size: 15px;
        color: #999;
      }
    }
  }

  .extra {
    background-color: #fff;
  }

  .slogan {
    height: 178px;
    line-height: 58px;
    padding: 60px 100px;
    border-bottom: 1px solid #434343;
    display: flex;
    justify-content: space-between;

    a {
      height: 58px;
      line-height: 58px;
      color: #fff;
      font-size: 28px;

      i {
        font-size: 50px;
        vertical-align: middle;
        margin-right: 10px;
        font-weight: 100;
      }

      span {
        vertical-align: middle;
        text-shadow: 0 0 1px #333;
      }
    }
  }

  .copyright {
    padding: 40px 0;
    text-align: center;
    color: #999;
    font-size: 15px;

    .line-p {
      width: 910px;
      line-height: 1;
      margin: 0 auto 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #dfe0e3;

      a {
        color: #111;
        line-height: 1;
        margin-right: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .line-p1 {
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 30px;

      a {
        line-height: 1;
        color: #9499a3;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .line-pic-bar {
      width: 689px;
      height: 53px;
      margin: 0 auto;
      background-image: url(../assets/images/footer/ft-bar.png);
      background-size: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .a-item {
        width: 100px;
        height: 40px;
        // background-color: red;
      }
    }
  }
}
</style>
