<template>
  <div class="container">
    <div class="exchange-bar">
      <div class="exchange-title">
        <img class="ex-icon"
             src="@/assets/images/home/ex-title1.png"
             alt="">
        产地直供
      </div>
      <div class="exchange-title">
        <img class="ex-icon"
             src="@/assets/images/home/ex-title2.png"
             alt="">
        全程溯源
      </div>
      <div class="exchange-title">
        <img class="ex-icon"
             src="@/assets/images/home/ex-title3.png"
             alt="">
        正品保证
      </div>
      <div class="exchange-title">
        <img class="ex-icon"
             src="@/assets/images/home/ex-title4.png"
             alt="">
        售后无忧
      </div>
    </div>
    <div class="exchange-area">

      <swiper :slides-per-view="3"
              :space-between="50"
              :modules="[Autoplay]"
              :loop="true"
              :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }">
        <template v-for="(item, index) in activityList"
                  :key="index">
          <swiper-slide class="exchange-item">
            <div @click="toGo(item)">
              <img v-lazy="item.pictureUrl"
                   alt="">
            </div>
          </swiper-slide>
        </template>

      </swiper>

    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { queryUrlParmas } from '../../../utils/utils'

export default defineComponent({
  name: 'HomeExchangeArea',
  props: {
    activityList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup () {
    const router = useRouter()
    const toGo = (row) => {
      console.log(row)
      // linkType - 1：去商品详情 / 2：去商家 / 3：去外链
      if (row.linkType == 1) {
        router.push('goods?goodsId=' + row.linkTypeId + '&ownSale=' + row.ownSale +
          '&storeId=' + row.storeId)
      } else if (row.linkType == 2) {
        // uni.navigateTo({
        // 	url: '/page_store/pages/index?id=' + row.supplierId
        // })
      } else if (row.linkType == 3) {
        if (row.linkUrl.indexOf('/pages/index/theme-item')) {
          console.log(row.linkUrl)
          let query = queryUrlParmas(row.linkUrl)
          console.log(query)
          router.push({
            path: 'themeActive',
            query
          })
          return
        }
        if (!row.linkUrl) return;
        window.location.href = row.linkUrl;
      }
    }
    return {

      toGo,
      Autoplay
    };
  }
})
</script>
<style scoped lang="less">
.container {
  width: 597px;
  height: 275px;
  background-color: #fff;
  padding: 20px;
  .exchange-bar {
    width: 506px;
    height: 23px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .exchange-title {
      width: 110px;
      font-size: 16px;
      font-weight: 400;
      color: #2a3345;
      line-height: 24px;
      .ex-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
  }
  .exchange-area {
    display: flex;
    height: 191px;
    background: #ffffff;
    .exchange-item {
      width: 175px;
      height: 191px;
      background: #ffffff;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      .ex-item-pic {
        width: 175px;
        height: 132px;
        background-color: red;
      }
      .ex-slogan {
        position: relative;
        width: 175px;
        height: 59px;
        background-color: #111;
        color: #fff;
        font-size: 17px;
        padding-top: 24px;
        .ex-price {
          width: 120px;
          height: 35px;
          line-height: 35px;
          position: absolute;
          left: 50%;
          margin-left: -60px;
          top: -17px;
          background: #fadc54;
          color: #111;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
