import { ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

export const useLazyData = (dataKey, apiFn) => {
  const list = ref([])
  const target = ref(null)
  // console.log(target)
  const { stop } = useIntersectionObserver(
    target,
    ([{ isIntersecting }]) => {
      // console.log(isIntersecting, '元素可见性')
      if (isIntersecting) {
        // 模拟发送请求业务
        // console.log('元素可见, 发送网络请求~~~~~')
        // 🔔 请求函数作为参数传递进来
        apiFn().then(({ data }) => {
          list.value = (dataKey && data[dataKey]) || data
        })
        // 请求发送后，停止检查
        stop()
      }
    }
  )

  return { list, target }
}
