<template>
  <div class="page-home">
    <div class="home-entry">
      <div class="container top-main">
        <!-- 左侧分类 -->
        <HomeCategory />
        <!-- 中间模块 -->
        <center class="top-main-center">
          <HomeBanner :bannerList="bannerList" />
          <HomeExchangeArea :activityList="activityList" />
        </center>
        <!-- 右侧文化馆入口模块 -->
        <div class="top-main-right">
          <HomeCulturalCenter />
        </div>
      </div>
    </div>
    <!-- 首页楼层开始 -->
    <HomePavilion />
    <HomeVideos />
    <HomeArticles />

  </div>
</template>
<script >
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'home'
})
</script>

<script setup>
import HomeBanner from './components/home-banner.vue'
import HomeExchangeArea from './components/home-exchange-area.vue'
import HomeCulturalCenter from './components/home-cultural-center.vue'
import HomeCategory from './components/home-category.vue'
import HomeVideos from './components/home-videos.vue'
import HomeArticles from './components/home-articles.vue'
import HomePavilion from './components/home-pavilion.vue'

import { getBannerList, } from '@/api/home.js'

import { toRefs, reactive } from 'vue'
import { addUrlPrefix } from '@/utils/methods'
const {
  bannerList,
  activityList
} = toRefs(reactive({
  bannerList: [],
  activityList: []
}))

getBannerList()
  .then(({ data }) => {
    bannerList.value = data.bannerList.map(v => {
      v.hrefUrl = v.linkUrl
      v.imgUrl = addUrlPrefix(v.pictureUrl)
      return v
    })
    activityList.value = data.activityList
  })


</script>

<style scoped lang="less">
.page-home {
  background: #fafcff;
}
.home-entry {
  position: relative;
  height: 810px;
  // margin-bottom: 30px;
  background-image: url("~@/assets/images/home/bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .top-main {
    position: absolute;
    height: 525px;
    bottom: 0;
    left: 50%;
    margin-left: -620px;
    .top-main-center {
      position: absolute;
      left: 291px;
      top: 0;
      padding: 2px 2px 0;
      background-color: #fff;
    }
    .top-main-right {
      position: absolute;
      left: 902px;
      top: 0;
    }
  }
}
</style>
