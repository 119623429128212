export function setSession (key, value) {
  const str = window.JSON.stringify(value);
  window.sessionStorage.setItem(key, str);
}

export function getSession (key) {
  let json
  json = window.sessionStorage.getItem(key);
  if (json) json = window.JSON.parse(json);
  return json;
}

export function setLocal (key, value) {
  const str = window.JSON.stringify(value);
  window.localStorage.setItem(key, str);
}

export function getLocal (key) {
  let json;
  json = window.localStorage.getItem(key);
  if (json) json = window.JSON.parse(json);
  return json;
}
export function clearLocal () {
  window.localStorage.clear()
}
// 函数节流
// fn 是需要执行的函数
// wait 是时间间隔
export function throttle (fn, wait = 50) {
  // 上一次执行 fn 的时间
  let previous = 0
  // 将 throttle 处理结果当作函数返回
  return function (...args) {
    // 获取当前时间，转换成时间戳，单位毫秒
    let now = +new Date()
    // 将当前时间和上一次执行函数的时间进行对比
    // 大于等待时间就把 previous 设置为当前时间并执行函数 fn
    // console.log(now - previous)
    if (now - previous > wait) {
      previous = now
      fn.apply(this, args)
    }
  }
}
export function queryUrlParmas (url) {
  url = url ?? window.location.href
  let reg_url = /^[^\?]+\?([\w\W]+)$/,
    reg_para = /([^&=]+)=([\w\W]*?)(&|$|#)/g,
    arr_url = reg_url.exec(url),
    ret = {};
  if (arr_url && arr_url[1]) {
    let str_para = arr_url[1],
      result;
    while ((result = reg_para.exec(str_para)) != null) {
      ret[result[1]] = result[2];
    }
  }
  return ret;
}

// scrollTop animation
export const scrollTop = function (el, from = 0, to, duration = 500, endCallback) {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (
      window.webkitRequestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 1000 / 60)
      }
    )
  }

  const difference = Math.abs(from - to)
  const step = Math.ceil(difference / duration * 50)

  function scroll (start, end, step) {
    if (start === end) {
      endCallback && endCallback()
      return
    }

    let d = (start + step > end) ? end : start + step
    if (start > end) {
      d = (start - step < end) ? end : start - step
    }

    if (el === window) {
      window.scrollTo(d, d)
    } else if (el instanceof Element) {
      el.scrollTop = d
    }
    window.requestAnimationFrame(() => scroll(d, end, step))
  }

  scroll(from, to, step)
}
