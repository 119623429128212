<template>
  <div class="home-pavilion">
    <div class="home-pavilion-title"></div>
    <div class="home-pavilion-list container"
         ref="target">

      <qdzCarousel :sliders="list"
                   auto-play>

        <!-- <template v-for="(item,index) in list"
                  :key="index"> -->
        <template v-slot:default="sList">
          <div class="pavilion-list-item">
            <div class="pavilion-item"
                 v-for="(sitem,index) in sList.item"
                 :key="index"
                 @click="$router.push(`/localPavilion/cityPavilion?id=${sitem.id}&areaType=0`)">
              <img class="pavilion-item-pic"
                   :src="sitem.pic"
                   alt="">
              <div class="pavilion-item-bar">

                <div class="title HeiJian">{{sitem.venueName}}</div>
                <div class="follow">{{$readNumFormat(sitem.followCount)}}关注</div>
              </div>
            </div>
          </div>

        </template>

      </qdzCarousel>

      <div class="see-more"
           @click="$router.push('/localPavilion')">查看全部</div>
    </div>
  </div>
</template>
<script>
import { getVenueList } from '@/api/localPavilion'
import { ref, } from 'vue'
import { useLazyData } from '@/hooks'
import p1 from '@/assets/images/home/p1.png'
import p2 from '@/assets/images/home/p2.png'
import p3 from '@/assets/images/home/p3.png'
import p4 from '@/assets/images/home/p4.png'
import HomeSkeleton from './home-skeleton.vue'
export default {
  name: 'HomePavilion',
  components: {
    HomeSkeleton
  },
  setup () {
    const list = ref([
      [
        {
          areaType: 0,
          favoriteCount: 3,
          followCount: 4536,
          goodsCount: 113,
          goodsSaleCount: 0,
          id: 358,
          isFavorite: 0,
          isSale: 0,
          provinceId: 4523850,
          provinceName: "湖北省",
          venueName: "湖北馆",
          venueType: 0,
          pic: p1
        },
        {
          areaType: 0,
          favoriteCount: 2,
          followCount: 3273,
          goodsCount: 63,
          goodsSaleCount: 0,
          id: 368,
          isFavorite: 0,
          isSale: 0,
          provinceId: 4525061,
          provinceName: "陕西省",
          venueName: "陕西馆",
          venueType: 0,
          pic: p2

        },
        {
          areaType: 0,
          favoriteCount: 41,
          followCount: 5446,
          goodsCount: 583,
          goodsSaleCount: 0,
          id: 360,
          isFavorite: 0,
          isSale: 0,
          provinceId: 4524130,
          provinceName: "广东省",
          venueName: "广东馆",
          venueType: 0,
          pic: p3
        },
        {
          areaType: 0,
          favoriteCount: 22,
          followCount: 5466,
          goodsCount: 90,
          goodsSaleCount: 0,
          id: 352,
          isFavorite: 0,
          isSale: 0,
          provinceId: 4523000,
          provinceName: "浙江省",
          venueName: "浙江馆",
          venueType: 0,
          pic: p4
        }
      ]
    ])
    //先写死4个数据组
    // const getList = () => {
    //   getVenueList({
    //     pageSize: '35'
    //   }).then(res => {
    //     console.log(res)
    //     // 将得到得省数据按四个进行分组
    //     let result = [];

    //     for (let i = 0, len = res.data.list.length; i < len; i += 4) {

    //       result.push(res.data.list.slice(i, i + 4));

    //     }
    //     list.value = result
    //     console.log(result)
    //   })
    // }
    // getList()
    return {
      list,
      p1,
      p2,
      p3,
      p4
    }
  }
}
</script>
<style scoped lang="less">
.home-pavilion {
  height: 1418px;
  background-image: url("../../../assets/images/home/bg/1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .home-pavilion-title {
    height: 655px;
    background-image: url("../../../assets/images/home/bg-pavilion.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: 40px;
  }
  .home-pavilion-list {
    height: 940px;
    position: relative;
    top: -50px;
    .pavilion-list-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .pavilion-item {
      width: 612px;
      height: 448px;
      margin-bottom: 24px;
      &:nth-child(odd) {
        margin-right: 14px;
      }
      .hoverShadow();
      .pavilion-item-pic {
        width: 612px;
      }
      .pavilion-item-bar {
        width: 612px;
        height: 103px;
        background-color: #8b3a39;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .title {
          font-size: 38px;
          font-weight: 400;
          color: #ffffff;
        }
        .follow {
          width: 146px;
          height: 48px;
          background: #fadc54;
          border-radius: 59px 59px 59px 59px;
          overflow: hidden;
          font-size: 20px;
          text-align: center;
          line-height: 48px;
          font-weight: bold;
          color: #8b3939;
        }
      }
    }
    .see-more {
      width: 214px;
      height: 78px;
      background: #8c3737;

      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      bottom: -88px;
      left: 50%;
      margin-left: -107px;
      text-align: center;
      line-height: 78px;
      cursor: pointer;
      opacity: 0.75;
    }
  }
}
</style>