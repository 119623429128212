<template>
  <div class="home-videos">
    <div class="home-videos-title"
         ref="target"></div>
    <Transition>
      <div class="home-videos-list container"
           v-if="list.length">
        <div class="videos-item"
             v-for="(item, index) in list"
             :key="index"
             @click="$router.push(`/culturalCenter/detail?contentId=${item.contentId}`)">
          <div class="videos-item-pic">
            <img v-lazy="item.videoCover"
                 class="img"
                 :alt="item.brief">
          </div>
          <div class="videos-item-title ellipsis-2"
               :title="item.title">
            {{item.title}}
          </div>
        </div>

        <div class="see-more"
             @click="$router.push('/culturalCenter?curIndex=1')">查看全部</div>
      </div>
      <HomeSkeleton style="top: 20px;    position: relative;"
                    v-else />

    </Transition>

  </div>
</template>

<script>
import { getCultureVideoList } from '@/api/home.js'
import { useLazyData } from '@/hooks'
import HomeSkeleton from './home-skeleton.vue'
export default {
  name: 'HomeVideos',
  components: {
    HomeSkeleton
  },
  setup () {
    return {
      ...useLazyData('videoEasyInfos', () => getCultureVideoList({ currentPage: 1, pageSize: 10 })),
    }
  }
}
</script>
<style scoped lang="less">
.home-videos {
  height: 1352px;
  background-image: url("~@/assets/images/home/bg/2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .home-videos-title {
    height: 860px;
    background-image: url("~@/assets/images/home/bg-videos.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: 280px;
  }
  .home-videos-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 20px;
    height: 658px;
    .videos-item {
      width: 240px;
      height: 301px;
      background: #ffffff;
      opacity: 1;
      margin-right: 10px;
      margin-bottom: 28px;
      .hoverShadow();
      &:nth-child(5n) {
        margin-right: 0;
      }
      .videos-item-pic {
        width: 240px;
        height: 217px;
        padding: 25px 19px;
        .img {
          width: 202px;
          height: 167px;
          background: #fff;
          object-fit: cover;
        }
      }
      .videos-item-title {
        font-size: 14px;
        font-weight: 500;
        color: #5f6674;
        padding: 16px;
        height: 55px;
      }
    }
    .see-more {
      width: 214px;
      height: 78px;
      background: #8c3737;

      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      bottom: -88px;
      left: 50%;
      margin-left: -107px;
      text-align: center;
      line-height: 78px;
      cursor: pointer;
      opacity: 0.75;
    }
  }
}
</style>