export * from './type';
export * from './dom';

export const isBoolean = (val) => typeof val === 'boolean';

export const isFunction = (val) => typeof val === 'function';

export const isArray = Array.isArray;

export const isNumber = (val) => typeof val === 'number';

