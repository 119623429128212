<template>
  <ul class="app-header-nav">
    <li @click="$router.push('/')">
      首页
    </li>
    <li v-for="item in categoryList"
        :key="item.name"
        :class="{active:$route.path.indexOf(item.path)>-1}"
        @click="$router.push(item.path)">
      <!-- @mouseenter="show(item)"
      @mouseleave="hide(item)" -->
      <!-- <RouterLink :to="item.path" -->
      <!-- @click="hide(item)"> -->
      {{ item.name }}
      <!-- </RouterLink> -->
      <!-- <div class="layer" :class="{ open: item.open }">
        <ul>
          <li v-for="item2 in item.children" :key="item2.id">
            <RouterLink :to="`/category/sub/${item2.id}`" @click="hide(item)">
              <img :src="item2.picture" alt="" />
              <p>{{ item2.name }}</p>
            </RouterLink>
          </li>
        </ul>
      </div> -->
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppHeaderNav',
  setup () {

    const categoryList = [
      {
        name: '地方馆',
        id: '1',
        path: '/localPavilion'
      },
      {
        name: '文化馆',
        id: '2',
        path: '/culturalCenter'
      },
      {
        name: '家乡贡献值',
        id: '3',
        path: '/contributionValue'
      },
      {
        name: '三爱大使',
        id: '4',
        path: '/ambassador'
      }
    ]
    return { categoryList }
  }
}
</script>

<style scoped lang="less">
.app-header-nav {
  width: 642px;
  height: 100%;
  display: flex;
  // justify-content: space-around;
  padding-left: 83px;
  position: relative;
  z-index: 998;
  margin-right: 40px;
  > li {
    height: 78px;
    padding: 0 17px;
    line-height: 78px;
    text-align: center;
    margin-right: 29px;
    color: #fff;
    &:last-child {
      margin-right: 0;
    }

    > a {
      color: #fff;
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      display: inline-block;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      cursor: pointer;
      // > a {
      //   color: @qdzColor;
      //   border-bottom: 1px solid @qdzColor;
      // }
      // // 子分类
      // > .layer {
      //   height: 132px;
      //   opacity: 1;
      // }
    }
    &.active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
// 子分类
.layer {
  // .layer .open 同时存在
  &.open {
    height: 132px;
    opacity: 1;
  }
  width: 1240px;
  background-color: #fff;
  position: absolute;
  left: -200px;
  top: 56px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.2s 0.1s;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 70px;
    align-items: center;
    height: 132px;
    li {
      width: 110px;
      text-align: center;
      img {
        width: 60px;
        height: 60px;
      }
      p {
        padding-top: 10px;
      }
      &:hover {
        p {
          color: @qdzColor;
        }
      }
    }
  }
}
</style>
