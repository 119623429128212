<template>
  <div class="home-banner">
    <!-- <qdzCarousel :sliders="bannerList"
                 auto-play /> -->
    <swiper :style="{
      '--swiper-pagination-color': '#fff',
    }"
            :modules="[Autoplay,Pagination ,EffectFade]"
            :loop="true"
            :pagination="{
      clickable: true,
    }"
            :effect="'fade'"
            :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }">
      <template v-for="(item, index) in bannerList"
                :key="index">
        <swiper-slide class="exchange-item">
          <div @click="toGo(item)">
            <img v-lazy="item.pictureUrl"
                 alt="">
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { Autoplay, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { queryUrlParmas } from '@/utils/utils'
import { defineComponent } from 'vue-demi';
const props = defineProps({
  bannerList: {
    type: Array,
    default: () => [{
      hrefUrl: '',
      imgUrl: banner
    }]
  }
})

const router = useRouter()
const toGo = (row) => {
  console.log(row)
  // linkType - 1：去商品详情 / 2：去商家 / 3：去外链
  if (row.linkType == 1) {
    let toPath = router.resolve('goods?goodsId=' + row.linkTypeId + '&ownSale=' + row.ownSale +
      '&storeId=' + row.storeId)
    window.open(toPath.href, '_blank')
  } else if (row.linkType == 2) {
    // uni.navigateTo({
    // 	url: '/page_store/pages/index?id=' + row.supplierId
    // })
  } else if (row.linkType == 3) {
    if (row.linkUrl.indexOf('/pages/index/theme-item') > -1) {
      let query = queryUrlParmas(row.linkUrl)
      let toPath = router.resolve({
        path: 'themeActive',
        query
      })
      window.open(toPath.href, '_blank')
      return
    }
    if (!row.linkUrl) return;
    let href = row.linkUrl;
    window.open(href, '_blank')
  }
}
</script>

<style scoped lang="less">
.home-banner {
  width: 597px;
  height: 250px;
  position: relative;
  left: 0;
  top: 0;
  z-index: 98;
  :deep(.swiper) {
    width: 100%;
    height: 100%;
  }
  .exchange-item {
    height: 250px;
    img {
      width: 597px;
      height: 250px;
      object-fit: cover;
    }
  }
}
.qdz-carousel {
  // :deep(.carousel-btn.prev) {
  //   left: 270px !important;
  // }
  // :deep(.carousel-indicator) {
  //   padding-left: 250px;
  // }
}
</style>
