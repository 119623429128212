<template>
  <header class="app-header" :class="{ show: y >= 78 }">
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">千度真</RouterLink>
      </h1>
      <AppHeaderNav></AppHeaderNav>
      <div class="search">
        <div class="search-bar">
          <i class="iconfont icon-search"></i>
          <input class="ellipsis" type="text" placeholder="搜索商品" v-model="keyVal" @keyup="handleSearchKeyword($event)"
            @keydown.enter="$router.push({
              path: 'search',
              query: {
                keyword: keyVal
              }
            }); keyVal = keyVal; showListFlag = false" @blur="showListFlag = false" @focus="showListFlag = true" />

        </div>
        <div>
          <div class="keyword-list" :class="{ fade: currentList.length && (keyVal != '') && showListFlag }" ref="cont">
            <div class="wrap" ref="wrap">
              <div class="item ellipsis" v-for="(item, index) in currentList" :key="index" @click="$router.push({
                path: 'search',
                query: {
                  keyword: item.word
                }
              }); keyVal = item.word">{{ item.word }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="cart">
        <a class="curr" href="#">
          <i class="iconfont icon-cart"></i><em>2</em>
        </a>
      </div> -->
      <div class="qr-code-text" @mouseenter="active = true" @mouseleave="active = false">
        <i class="iconfont-p icon-saomiao"></i>
        扫码进店
        <div class="qr-box" :class="{ fade: active }">
          <img class="img" src="../assets/images/qrcode.jpg" alt="">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import AppHeaderNav from './app-header-nav.vue'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useVirtualList } from '@/hooks/useVIrtualList.js'
import useThrottleFn from "@/hooks/useThrottleFn"
import { searchHotWordList } from '@/api/home'
import { useWindowScroll } from '@vueuse/core'

export default {
  components: { AppHeaderNav },
  name: 'AppHeader',
  setup (props) {
    const route = useRoute()
    let active = ref(false)
    const wrap = ref()
    const cont = ref()
    const keyVal = ref('')
    const showListFlag = ref(false)
    const currentList = ref([])
    const [handleSearchKeyword] = useThrottleFn(() => {
      searchHotWordList({ keyword: keyVal.value }).then(res => {
        currentList.value = res.data
      })
    }, 200)
    watch(() => route.query, (value) => {
      keyVal.value = value.keyword

    }, { immediate: true })
    const { y } = useWindowScroll()

    return {
      wrap,
      cont,
      active,
      currentList,
      keyVal,
      showListFlag,
      handleSearchKeyword,
      y
    }
  }
}
</script>

<style scoped lang="less">
.app-header {
  width: 100%;
  height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #111;
  // border-bottom: 1px solid #e4e4e4;
  transform: translateY(-100%);
  opacity: 0.5;

  &.show {
    transition: all 0.3s linear;
    transform: translateY(0);
    opacity: 1;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 132px;

    a {
      display: block;
      height: 78px;
      width: 100%;
      text-indent: -9999px;
      background: url(~@/assets/images/logo.png) no-repeat center 14px / contain;
      background-size: 132px;
      // background-color: #0094ff;
    }
  }

  .search {
    position: relative;

    .keyword-list {
      width: 270px;
      height: 0;
      overflow: auto;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      border-radius: 10px;
      margin-top: 10px;
      opacity: 0;
      transition: all 0.3s linear;
      z-index: 99;

      &.fade {
        opacity: 1;
        height: 300px;
      }

      /*chrome--------------------------------------------start*/
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: rgb(255, 255, 255);
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(201, 201, 202);
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(162, 162, 163);
      }

      /*chrome--------------------------------------------end*/
      /*IE--------------------------------------------start*/
      * {
        /*三角箭头的颜色*/
        scrollbar-arrow-color: #c0c4cc;
        /*滚动条滑块按钮的颜色*/
        scrollbar-face-color: #a2a2a3;
        /*滚动条整体颜色*/
        scrollbar-highlight-color: #a2a2a3;
        /*滚动条阴影*/
        scrollbar-shadow-color: #a2a2a3;
        /*滚动条轨道颜色*/
        scrollbar-track-color: #f4f4f5;
        /*滚动条3d亮色阴影边框的外观颜色——左边和上边的阴影色*/
        scrollbar-3dlight-color: #a2a2a3;
        /*滚动条3d暗色阴影边框的外观颜色——右边和下边的阴影色*/
        scrollbar-darkshadow-color: #a2a2a3;
        /*滚动条基准颜色*/
        scrollbar-base-color: #f4f4f5;
      }

      /*IE--------------------------------------------end*/
      .item {
        width: 80%;
        height: 54px;
        text-align: center;
        line-height: 54px;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .search-bar {
    width: 277px;
    height: 34px;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    // border-bottom: 1px solid #e7e7e7;
    border-radius: 17px;
    margin-right: 34px;
    overflow: hidden;
    line-height: 34px;

    .icon-search {
      font-size: 16px;
      margin: 0 13px;
      color: #666;
    }

    input {
      width: 180px;
      padding-left: 5px;
      color: #fff;
      background: transparent;

      &::-webkit-input-placeholder {
        /* placeholder颜色  */
        color: #666;
      }
    }
  }

  .cart {
    width: 50px;

    .curr {
      height: 32px;
      line-height: 32px;
      text-align: center;
      position: relative;
      display: block;

      .icon-cart {
        font-size: 22px;
      }

      em {
        font-style: normal;
        position: absolute;
        right: 0;
        top: 0;
        padding: 1px 6px;
        line-height: 1;
        background: @helpColor;
        color: #fff;
        font-size: 12px;
        border-radius: 10px;
        font-family: Arial;
      }
    }
  }

  .qr-code-text {
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    position: relative;

    .qr-box {
      width: 130px;
      height: 150px;
      border-radius: 20px;
      position: absolute;
      left: -50px;
      background-color: #fff;
      opacity: 0;
      transition: all 0.3s linear;
      z-index: 99;
      transform: translateY(-100%);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &.fade {
        opacity: 1;
        transform: translateY(0);
      }

      .img {
        width: 110px;
        height: 110px;
        display: block;
      }
    }
  }
}
</style>
