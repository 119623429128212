import {
  computed,
  onMounted,
  Ref,
  unref,
  watchEffect
} from "vue"
import useEventListener from "./useEventListener"
import useNumRangeRef from "./useNumRangeRef"
import useThrottleFn from "./useThrottleFn"
import {
  getTargetElement,
  isNumber,
} from "@/utils/use/index"



const useVirtualList = ({
  container,
  wrap,
  list
}, {
  itemHeight,
  overscan = 5
}) => {
  if (!itemHeight) {
    throw '请传入item的高度'
  }

  const listLength = computed(() => unref(list).length)

  const start = useNumRangeRef(0, {
    min: 0,
    max: listLength
  })
  const end = useNumRangeRef(10, {
    min: 10,
    max: listLength
  })

  const containerEl = getTargetElement(container)
  const wrapEl = getTargetElement(wrap)

  // 计算container里能放多少个item
  const getViewCapacity = (containerHeight) => {
    if (isNumber(itemHeight)) {
      return Math.ceil(containerHeight / itemHeight)
    } else {
      let sum = 0
      let capacity = 0

      for (let i = start.value, len = listLength.value; i < len; i++) {
        sum += itemHeight(i, list[i])

        if (sum >= containerHeight) {
          capacity = i - start.value;
          break
        }
      }
      return capacity
    }
  }

  // 计算container上面有多少个item
  const getOffset = (scrollTop) => {
    if (isNumber(itemHeight)) {
      return Math.ceil(scrollTop / itemHeight)
    } else {
      let sum = 0,
        offset = 0;
      for (let i = start.value, len = listLength.value; i < len; i++) {
        sum += itemHeight(i, list[i])
        if (sum >= scrollTop) {
          offset = i + 1;
          break
        }
      }
      return offset
    }
  }

  // 计算item离顶部的距离
  const distanceTopCache = []
  const getDistanceTop = (index) => {
    if (distanceTopCache[index]) return distanceTopCache[index] // 有缓存从缓存拿

    if (isNumber(itemHeight)) {
      return (distanceTopCache[index] = index * itemHeight)
    } else {
      let height = 0
      for (let i = 0; i < index; i++) {
        height += itemHeight(i, list[i])
      }
      return (distanceTopCache[index] = height)
    }
  }

  // 计算要显示哪些item
  const calculate = () => {
    const el = containerEl.value
    if (!el) return

    const {
      scrollTop,
      clientHeight
    } = el
    const offset = getOffset(scrollTop)
    const visibleCount = getViewCapacity(clientHeight)

    start.value = offset - overscan
    end.value = offset + visibleCount + overscan
  }

  const sumHeight = computed(() => {
    if (isNumber(itemHeight)) {
      return itemHeight * listLength.value;
    } else {
      return unref(list).reduce((sum, _, index) => {
        return (sum += itemHeight(index, list[index]));
      }, 0);
    }
  });

  const offsetTop = computed(() => getDistanceTop(start.value));

  const [scrollHandler] = useThrottleFn((e) => {
    e.preventDefault()
    calculate()
  }, 100)

  onMounted(() => {
    calculate()
    useEventListener('scroll', scrollHandler, {
      target: containerEl.value
    })
    // console.log(containerEl)
    containerEl.value.style.overflow = 'auto'
    wrapEl.value.style.width = '100%'
    watchEffect(() => {
      Object.assign(wrapEl.value.style, {
        height: `${sumHeight.value - offsetTop.value}px`,
        marginTop: `${offsetTop.value}px`,
      })
    })
  })

  return [
    computed(() => {
      return unref(list)
        .slice(start.value, end.value)
        .map((item, index) => ({
          data: item,
          index: index + start.value,
        }));
    }),

    (index) => {
      if (containerEl.value) {
        containerEl.value.scrollTop = getDistanceTop(index);
        calculate();
      }
    },
  ];
}

export {
  useVirtualList
}
