<template>
  <div class="container">
    <div class="cultural-heard"
         @click="$router.push('/culturalCenter')">
      <img src="@/assets/images/home/cultural-1.png"
           alt="">
      <!-- <div class="h-top">
               <div class="h-pic"></div>
               <div class="h-title">
                   <div class="h-t1">
                       文化馆
                   </div>
                   <div class="h-t2">
                       一县一特
                   </div>
               </div>
           </div>
           <div class="h-btm">
               <div class="h-text">CULTURAL CENTER</div>
           </div> -->
    </div>
    <div class="cultral-main">
      <div class="cultral-entry"
           v-for="(item , index) in assistantImage"
           :key="index">
        <img v-lazy="item.bannerPic"
             alt=""
             @click="$router.push('/culturalCenter?curIndex=2&themeIndex='+index)">
      </div>

    </div>
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive } from 'vue'
import { getCultureListInfo } from '@/api/home'
export default defineComponent({
  name: 'HomeCulturalCenter',
  setup (props) {
    const state = reactive({
      assistantImage: [],
    })
    getCultureListInfo().then(({ data }) => {
      // console.log(data)
      state.assistantImage = data.assistantImage
    })

    return {
      ...toRefs(state)
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 339px;
  height: 525px;
  background-color: #fff;
  padding: 10px 12px;
}
.cultural-heard {
  position: relative;
  margin-bottom: 18px;
  cursor: pointer;
  // &::before{
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     width: 1px;
  //     height: 34px;
  //     background-color: #111;
  //     opacity: .1;
  //     left: 0;
  //     top: 37px;
  // }
  // &::after{
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     width: 83px;
  //     height: 1px;
  //     background-color: #111;
  //     opacity: .1;
  //     right: 38px;
  //     bottom: 4px;
  // }
  .h-top {
    display: flex;
    margin-bottom: 16px;
    .h-pic {
      width: 114px;
      height: 114px;
      margin: 0 30px 0 24px;
      background-color: yellow;
    }
    .h-title {
      padding-top: 32px;
      .h-t1 {
        font-size: 38px;
        font-weight: 500;
        line-height: 1;
        color: #111111;
        margin-bottom: 22px;
      }
      .h-t2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        color: #999;
        text-align: right;
      }
    }
  }
  .h-btm {
    color: #999;
    font-size: 12px;
    font-family: PingFang TC-Medium, PingFang TC;
    font-weight: 500;
  }
}
.cultral-main {
  display: flex;
  flex-wrap: wrap;
  .cultral-entry {
    width: 152px;
    height: 100px;
    margin-bottom: 12px;
    & > img {
      width: 100%;
      height: 100%;
    }
    &:nth-child(odd) {
      margin-right: 11px;
    }
  }
}
</style>
