import axios from 'axios'
// 导入仓库实例和路由实例
import router from '@/router'

// 导出服务器请求基地址
// export const baseURL = 'http://pcapi-xiaotuxian-front-devtest.itheima.net'
// export const baseURL = 'https://qdz.hsz88.com'

// 创建 axios 实例
const instance = axios.create({
  // 请求基地址
  // baseURL: baseURL,
  // 请求超时
  timeout: 10000
})

// 请求拦截器 request
instance.interceptors.request.use(
  config => {
    // 返回加工后的请求配置
    return config
  },
  err => {
    // 出错了
    return Promise.reject(err)
  }
)

// 响应拦截器 response
instance.interceptors.response.use(
  // 响应成功，更方便获取到后端数据
  res => res.data,
  // 响应失败
  err => {
    // 后端约定状态码 401 表示用户身份认证失败
    if (err.response && err.response.status === 401) {
      // 清空用户信息
      // 对路径进行安全转码
      const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
      // 跳转到登录页并把回跳路径存储起来
      router.push('/login?redirectUrl=' + fullPath)
    }
    // 返回失败
    return Promise.reject(err)
  }
)

/**
 * 请求函数封装
 * @param {String} url  请求地址
 * @param {String} method  请求方式
 * @param {Object} submitData 请求参数
 * @returns Promise
 */
const request = (url, method = 'get', submitData) => {
  return instance({
    url,
    method,
    // [] 动态计算 key 值，如果是 get 请求就用 params 传递请求参数，其他请用 data
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
  })
}
// 导出请求函数
export default request
