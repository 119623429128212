<template>
  <div class="qdz-carousel"
       @mouseenter="stop"
       @mouseleave="restart">
    <!-- 切换的主体 -->
    <ul class="carousel-body">
      <li class="carousel-item"
          v-for="(item,index) in sliders"
          :key="index"
          :class="{fade:activeIndex===index}">
        <slot :item="item">
          <RouterLink :to="item.hrefUrl">
            <img :src="item.imgUrl"
                 alt="" />
          </RouterLink>
        </slot>
      </li>

    </ul>
    <!-- 左右箭头 -->
    <a href="javascript:;"
       @click="toggle(-1)"
       class="carousel-btn prev">
      <i class="iconfont icon-angle-left"></i>
    </a>
    <a href="javascript:;"
       @click="toggle(1)"
       class="carousel-btn next">
      <i class="iconfont icon-angle-right"></i>
    </a>
    <!-- 指示器 -->
    <div class="carousel-indicator">
      <span v-for="(item,index) in sliders"
            :key="index"
            :class="{active:activeIndex===index}"
            @click="activeIndex = index"></span>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref, watch } from 'vue'
export default {
  name: 'qdzCarousel',
  props: {
    sliders: {
      type: Array,
      // Vue规定：数据类型如果是复杂类型(数组，对象)，default 的值必须是一个函数，函数的返回值作为默认值
      default: () => []
    },
    duration: {
      type: Number,
      default: 3000
    },
    autoPlay: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // 默认显示的图片索引
    const activeIndex = ref(0)
    const toggle = (number) => {
      let temp = activeIndex.value + number
      if (temp < 0) temp = props.sliders.length - 1
      if (temp > props.sliders.length - 1) temp = 0
      activeIndex.value = temp
    }

    // 封装一个用于自动播放的函数
    let trimerId
    const start = () => {
      clearInterval(trimerId)
      trimerId = setInterval(() => {
        toggle(1)
      }, props.duration)
    }

    watch(
      () => props.sliders, () => {
        if (props.sliders.length > 1 && props.autoPlay) {
          start()
        }
      }, { immediate: true })

    // 鼠标移入停止播放，鼠标移开重新启动定时器
    const stop = () => {
      clearInterval(trimerId)
    }
    const restart = () => {
      if (props.sliders.length > 1 && props.autoPlay) {
        start()
      }
    }

    // 组件卸载时，需要主动清除定时器（Vue是单页面应用）
    onUnmounted(() => {
      clearInterval(trimerId)
    })

    return { activeIndex, toggle, stop, restart }
  }
}
</script>

<style scoped lang="less">
.qdz-carousel {
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 150px;
  position: relative;
  .carousel {
    &-body {
      width: 100%;
      height: 100%;
    }
    &-item {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.5s linear;
      &.fade {
        opacity: 1;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-indicator {
      position: absolute;
      left: 0;
      bottom: 20px;
      z-index: 2;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        ~ span {
          margin-left: 12px;
        }
        &.active {
          background: #fff;
        }
      }
    }
    &-btn {
      width: 44px;
      height: 44px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -22px;
      z-index: 2;
      text-align: center;
      line-height: 44px;
      opacity: 0;
      transition: all 0.5s;
      &.prev {
        left: 20px;
      }
      &.next {
        right: 20px;
      }
    }
  }
  &:hover {
    .carousel-btn {
      opacity: 1;
    }
  }
}
</style>
