import {
  onUnmounted,
  watch
} from 'vue'
import {
  getTargetElement,
  isArray
} from '@/utils/use/index'

const normalize = (eventName, callback, options) => {
  const normalizeMap = {
    '11' (target, type) {
      (eventName).forEach((ev, index) => {
        target[type](ev, (callback)[index], options)
      })
    },

    '10' (target, type) {
      (eventName).forEach((ev) => {
        target[type](ev, callback, options)
      })
    },

    '01' (target, type) {
      (callback).forEach((fn) => {
        target[type](eventName, fn, options)
      })
    },

    '00' (target, type) {
      target[type](eventName, callback, options)
    }
  }

  return (target, type) => {
    // console.log(target)
    if (!target) return

    normalizeMap[+isArray(eventName) + (+isArray(callback) + '')](target, type)
  }
}

const useEventListener = (
  eventName,
  callback, {
    target,
    defaultTarget = window,
    ...options
  } = {}
) => {
  const el = getTargetElement(target, defaultTarget)

  const handler = normalize(eventName, callback, options)

  watch(
    el,
    (newTarget, oldTarget) => {
      handler(oldTarget, 'removeEventListener')
      handler(newTarget, 'addEventListener')
    }, {
    immediate: true
  }
  )

  onUnmounted(() => {
    handler(el.value, 'removeEventListener')
  })
}

export default useEventListener
